import { defineComponent } from 'vue';
import { PopupWrapper } from '@hems/component';
import PartnerAccountInfo from '@/components/popup/accountinfo/installer/PartnerAccountInfo.vue';
export default defineComponent({
    name: 'InstallerAccountInfoPopup',
    components: {
        PopupWrapper,
        PartnerAccountInfo,
    },
    props: {
        roleNm: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    setup() {
        const updateAccountInfo = () => {
            // TODO: 추후 계정 정보 수정 기능 추가 시 개발
        };
        return {
            updateAccountInfo,
        };
    },
});
