import { defineComponent } from 'vue';
import { SettingInfoBlock, SettingInfoItem, TruncateInput } from '@hems/component';
import { useUserInfo } from '@hems/util';
export default defineComponent({
    name: 'PartnerAccountInfo',
    components: {
        SettingInfoBlock,
        SettingInfoItem,
        TruncateInput,
    },
    props: {
        roleNm: {
            type: String,
            required: true,
        },
    },
    emits: ['save'],
    setup() {
        const { userId } = useUserInfo();
        // 수정 기능 추가 시 주석 해제
        // let copyData: Partial<Nullable<InstallerAccountInfo>>;
        // const isEditable = ref(false);
        // const state = reactive<{
        //   data: Partial<Nullable<InstallerAccountInfo>>;
        //   currentEmail: string | null | undefined;
        // }>({
        //   data: { ...props.data },
        //   currentEmail: props.data?.email,
        // });
        // const onEdit = () => {
        //   copyData = cloneDeep(state.data);
        //   isEditable.value = true;
        // };
        // const onCancel = () => {
        //   state.data = copyData;
        //   isEditable.value = false;
        // };
        // const onSave = () => {
        //   emit(
        //     'save',
        //     pickBy(state.data, (v) => !Helper.isNull(v))
        //   );
        // };
        return {
            userId,
        };
    },
});
