import { defineComponent } from 'vue';
import { PopupWrapper, UserAccountInfo } from '@hems/component';
import { useHomeownerInfo } from '@hems/util';
export default defineComponent({
    name: 'GeneralAccountInfoPopup',
    components: {
        PopupWrapper,
        UserAccountInfo,
    },
    emits: ['close'],
    setup() {
        const { homeOwnerInfo } = useHomeownerInfo();
        const updateAccountInfo = () => {
            // TODO: 추후 계정 정보 수정 기능 필요 시 추가
        };
        return {
            updateAccountInfo,
            homeOwnerInfo,
        };
    },
});
